<template>
 
  <router-view/>


</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

#app {
  font-family: "Poppins", sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
html{
    font-family: "Poppins", sans-serif!important;
    font-optical-sizing: auto;
    font-weight: 500;
font-size: medium;
    font-style: normal;
}
.v-list-item__content {
  font-family: "Poppins", sans-serif!important;
  font-optical-sizing: auto;
    font-weight: 500;
font-size: medium;
    font-style: normal;

}
.v-card-title.text-h6 {

  font-family: "Poppins", sans-serif!important;
  font-optical-sizing: auto;
    font-weight: 500;
font-size: medium;
    font-style: normal;

}
.text-lg-h5 {
  font-family: "Poppins", sans-serif!important;
  font-optical-sizing: auto;
    font-weight: 500;
font-size: medium;
    font-style: normal;

}
h1.text-lg-h4{
  font-family: "Poppins", sans-serif!important;
  font-optical-sizing: auto;
    font-weight: 500;
font-size: medium;
    font-style: normal;
  font-size: 1.6rem !important;

}
.text-h6 {
  font-family: "Poppins", sans-serif!important;
  font-optical-sizing: auto;
    font-weight: 500;
font-size: medium;
    font-style: normal;

}
.text-lg-h5 {
  font-family: "Poppins", sans-serif!important;
  font-size: 1.5rem !important; 
}
.v-label.v-field-label {
  font-family: "Poppins", sans-serif!important;
  /* font-size: 1.5rem !important;  */

}
nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
.v-footer {
  margin-top:auto!important ;
}


</style>
