<template>
 <v-layout>
 <v-footer  style="background:  #333399;"  class=" text-center d-flex flex-column"
  >
  <v-row class="w-100  pa-12">
    <v-col  cols="12" sm="2" >
      <router-link to="/" class="text-white"> <v-img :width="350" style="margin-top: -1rem;" cover src="../../../public/images/logo1.png"></v-img></router-link>


    </v-col>
      <v-col  cols="12" sm="3" >
       <ul style="list-style-type: none;color: white;text-align: start;line-height:3;font-size: 14px;">
        <li>Help Center</li>
        <router-link to="/privacyPolicy" class="text-white"><li>Privacy and Cookies Statement</li></router-link>
        <router-link to="/about" class="text-white"> <li>About Tripper</li></router-link>
    
       </ul>
      </v-col>
      <v-col  cols="12" sm="2" >
        <ul style="list-style-type: none;color: white;text-align: start;line-height:3;font-size: 14px;">
          <router-link to="/sitemap" class="text-white"><li>Sitemap</li></router-link>
         <li style=" margin-left: -1rem;font-size: 14px;" > <v-list-item href="https://vendor.trippertravels.com/" target="_blank" style="text-decoration: underline;font-size: 14px;">Supplier Sign Up</v-list-item></li>


       </ul>
      </v-col>
      <v-col  cols="12" sm="2" >
        <ul style="list-style-type: none;color: white;text-align: start;line-height:3;font-size: 14px;">
        <li>Travel Agents</li>
        <li>Become an Affiliate</li>
        <li>News</li>
         
       </ul>
      </v-col>
      <v-col  cols="12" sm="3" >
        <ul style="list-style-type: none;color: white;text-align: start;line-height:3;font-size: 14px;">
        <li>Tripper blog</li>
        <router-link to="/termsAndConditions" class="text-white"> <li>Terms & Conditions</li>  </router-link> 
        <v-row >
      <v-col cols="12"  sm="1">
        <router-link to="/" class="text-white"><v-icon aria-hidden="false"> mdi-twitter</v-icon></router-link> 

      </v-col>
      <v-col cols="12"  sm="1">
        <router-link to="/" class="text-white"> <v-icon aria-hidden="false"> mdi-facebook</v-icon></router-link>

      </v-col>
      <v-col cols="12"  sm="1">
        <router-link to="/" class="text-white"><v-icon aria-hidden="false"> mdi-youtube</v-icon></router-link>

      </v-col>
    </v-row>    
       </ul>
      </v-col>
      <!-- <v-divider :thickness="3" class="pa-3"></v-divider> -->
         </v-row>

       
  </v-footer>
</v-layout>
</template>
<script>
export default{
    name: 'footer-list', 

    methods: {
      supplier() {
			this.$router.push('https://vendor.trippertravels.com/').then(() => { this.$router.go(0) })
      },
    }
}
</script>
<style>
.v-list-item__content {

font-size: 14px;
}
@media only screen and (max-width: 600px) {
.pa-12{
  padding: 0px !important;
  ;
}
}
</style>